import React from 'react';
import './home.scss';
import NextStepPanel from '../NextStepPanel';
import imgTick from '../_assets/images/tick.png';
// import imgAged from '../_assets/images/icon-home-aged.png';
// import imgDisability from '../_assets/images/icon-home-disability.png';
// import imgSocial from '../_assets/images/icon-home-social.png';

import arrowRightImg from '../_assets/images/right-arrow.png';

import titleImg from '../_assets/images/header-img-main-sm.jpg';

import imgHelpDisabled from '../_assets/images/help-disabled-sm.jpg';
import imgHelpElderly from '../_assets/images/help-elderly-sm.jpg';

// import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
export default function Home() {

  const [tipCounter, setCounter] = React.useState(0);

  const testimonies = [
      {
          text: "I've had the pleasure of having Agness as a support worker for my son, and I couldn't be more pleased. She's patient, understanding, and truly listens to our needs. Every time she visits, my son lights up. We're grateful to have her through Tcare for you.",
      },
      {
          text: "Tcare for you is a game-changer. Tatiana, our assigned support worker, has been such a blessing. She's proactive, and her years of experience are evident in the way she approaches situations. Our family can now breathe easier knowing we have someone like her by our side.",
      },
      {
          text: "I recently transitioned to having Olga as my support worker, and she's been quite the revelation. While we had a few hiccups initially, she made it a point to understand my requirements and adjusted accordingly. I appreciate her flexibility and dedication.",
      },
      {
          text: "As someone with a complex medical condition, having a nurse like Tatiana manage my medication has been invaluable. She's diligent, always checks in on me, and ensures I'm on track with my regimen. Her expertise and gentle manner make all the difference.",
      },
      {
          text: "I've been with Tcare for you for over a year and recently got to work with support worker Tatiana. She's been nothing short of excellent. Her approach is thorough, and she has an innate ability to make those she supports feel comfortable and heard.",
      },
      {
          text: "Our family has been using Tcare for you for a while now, and every interaction reaffirms our decision. Nurse Tatiana, especially, stands out. Her attention to detail when it comes to medicine management for my grandmother is impeccable. Kudos to the team!",
      },
      {
          text: "I wasn't sure about changing service providers initially, but Agness from Tcare for you made the transition smooth. She's compassionate, professional, and truly goes the extra mile. While I hope I don't have to switch again, I'm glad Agness is part of our journey now.",
      }
  ]
  return (
    <div>
        <div className='top-img-container'>
            <img src={titleImg} alt="Tcare for You" />
        </div>
        <div className='wrapper services-list-container w-50 my-5 text-centered-container divider-line-container title-container'>
            <div className='slogan-container'>
                <h1 className='text-smaller'>Quality Support, Personalized for You</h1>
            </div>
            <p className='heading'>We offer a wide range of services designed to support both senior citizens and individuals living with disabilities</p>
        </div>
        <div className='wrapper services-list-container w-80 my-5'>
            <div className='services-container'>
                <div className='services-item'>
                    <div className='heading my-3 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="35" className='m-2' />
                        <h3 className='m-2'>Disability Support</h3>
                    </div>
                    <p>Empowering people with disabilities to embrace a complete, joyful, and self-sufficient life</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-3 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="Aged care" height="35" className='m-2' />
                        <h3 className='m-2'>Aged Care</h3>
                    </div>
                    <p>Our goal is to support you in leading a rich and independent life</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-3 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="Social" height="35" className='m-2' />
                        <h3 className='m-2'>Social &amp; Lifestyle Support</h3>
                    </div>
                    <p>Transportation, friendly companionship and assistance for social outings and events</p>
                </div>
            </div>
        </div>
        <div className='wrapper slogan-container divider-line-container w-75'>
            <div className='divider-line'></div>
            <p className='text-smaller'>Learn more about our services</p>
        </div>
        <div className='wrapper services-overview-container'>
            <div className='services-overview-item-wrapper'>
                <div className='services-overview-img-container'>
                    <img src={imgHelpDisabled} alt="" />
                </div>
                <div className='services-overview-text-container'>
                    <h3>Supporting people with disabilities</h3>
                    <p>Whether it's providing in-home support, facilitating work or training attendance or offering assistance with various activities, our comprehensive range of services is designed to uplift and support people with disabilities.</p>
                    <p>We provide services on a private basis and under the National Disability Insurance Scheme (NDIS).</p>
                    <Link to="/services/disability-support" onClick={() => window.scrollTo(0, 0)} className='button ui primary my-2'>Learn more about disability services</Link>
                </div>
            </div>
            <br/><br/>
            <div className='services-overview-item-wrapper flex-reversed last'>
                <div className='services-overview-text-container'>
                    <h3>Helping elderly Australians </h3>
                    <p>We're dedicated to assisting seniors in staying independent and enjoying a quality life at home. </p>
                    <p>Our support is tailored to you, whether it's helping with shopping lists, going to the store with you or providing nursing care for more complex needs.</p>
                    <Link to="/services/aged-care-support" onClick={() => window.scrollTo(0, 0)} className='button ui primary my-2'>See all in-house support services</Link>
                </div>
                <div className='services-overview-img-container'>
                    <img src={imgHelpElderly} alt="" />
                </div>
            </div>
        </div>
        <NextStepPanel />
        
        <br/>
        <div className='wrapper ndis-tip-container my-5'>
            <div className='d-flex flex-row justify-content-center align-items-center my-4'>
                {/* <FontAwesomeIcon icon={faArrowsRotate} height="40"  className='rotate-icon mx-4' onClick={() => setCounter(count => count + 1)}/> */}
                <h3 className='m-0'>Feedback from our clients</h3>
            </div>
            <div className='d-flex flex-row'>
                <div className='d-flex justify-content-end align-items-center arrow-left' onClick={() => setCounter(count => count - 1)}><img src={arrowRightImg} alt="Previous"  /></div>

                    {testimonies.map((t, idx) =>
                        <div key={idx} className={tipCounter % testimonies.length === idx || tipCounter % testimonies.length === -idx ? 'description active h-auto' : 'description h-auto'}>
                            {/* <span className='text-align-left'>Tatiana: </span> */}
                            {/* <br/> */}
                            <p className='mb-0'>"{t.text}"</p>
                        </div>
                    )}
                    
                <div className='d-flex justify-content-end align-items-center arrow-right' onClick={() => setCounter(count => count + 1)}><img src={arrowRightImg} alt="Next"   /></div>
            </div>
            
        </div>
        <br/>
    </div>
  );
}