import './career.scss';
import { Link } from 'react-router-dom';
import ReachOutForm from '../ContactUs/ReachOutForm';
import imgTick from '../_assets/images/tick.png';
// import titleImgAboutUs from '../_assets/images/titleImg_about_us.png';

export default function Career() {
  return (
    <div>
      {/* <div className='top-img-container'>
        <img src={titleImgAboutUs}
            alt="Career" />
      </div> */}
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Join Our Team at Tcare for You</p>
          </div>
          <p className='heading'>At Tcare for You, we are passionate about making a positive impact in the lives of individuals living with disabilities. Our commitment to providing high-quality NDIS services is rooted in compassion, professionalism, and a drive to empower our clients to lead fulfilling lives.</p>
      </div>
      <br/>
      <br/>
      <div className='divider-line-container'>
        <div className='divider-line'></div>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Current Opportunities</p>
          </div>
          <p className='heading'>Explore the exciting career opportunities available at Tcare for You:</p>
          <div className='my-5 mx-5'>
            <ul className='p-0'><li><h3 className='text-align-left'>Support Worker</h3></li></ul>
            <div className=''>
              <p className='text-align-left'>Join our team as a support worker and provide essential care and assistance to individuals with disabilities.</p>
            </div>
          </div>
      </div>
      {/* <div className='wrapper w-40 positions-container px-4'>
        <div className='my-5 py-4 px-2 position-item-container'>
            <ul><li><h3 className='text-align-left'>Support Worker</h3></li></ul>
            <div className='my-2 mx-3'>
              <p>Join our team as a support worker and provide essential care and assistance to individuals with disabilities.</p>
            </div>
        </div>
      </div> */}

      <div className='wrapper prompt-container'>
            {/* <img src={imgCtapanelbg} alt="" className='bg' /> */}
            <div className='container d-flex flex-row justify-content-center'>
                <div className='d-flex flex-column mx-5 my-3 how-to-apply-text-container'>
                    <h2 className='title mb-4'>How to Apply:</h2>
                    <div className='description mb-2 '>If you are dedicated, compassionate and ready to make a difference, we invite you to explore our current job openings. Apply by submitting your resume and a cover letter explaining why you would be a valuable addition to the Tcare for You team.</div>
                </div>
                <div className='d-flex flex-column mx-5 my-5'>
                    <Link to="mailto: info@tcareforyou.com.au" className='button ui primary my-1 first'>Email: info@tcareforyou.com.au</Link>
                    <Link to="tel:+61411776984" className='button ui primary my-1'>Call +61 411 776 984</Link>
                      {/* <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)} className='button ui primary my-2'>Request a call back</Link> */}
                </div>
            </div>
        </div>

      {/* <div>
      <div className='divider-line-container'>
        <div className='divider-line'></div>
      </div>
      </div> */}
      {/* <div className='wrapper'>
        <div className='reach-out-container m-5 p-4 mx-auto'>
            <h2>Reach out to us</h2>
            <div className='my-4 mx-3'>
            <p >Please contact us and our team members will be in touch with you shortly</p>
            <ReachOutForm link="https://getform.io/f/f404465a-ba10-4b0d-bf53-27c6b34c3364" />
            </div>
        </div>
      </div> */}
      <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='wrapper divider-line-container w-50'>
                {/* <div className='divider-line'></div>
                <br></br><br></br><br></br> */}
                <h2 className='text-smaller'>Join us in our mission to provide exceptional care and support to those in need. </h2>
                <br/>
                <h2 className='text-smaller'>Together, we can make a real difference in the lives of individuals living with disabilities.</h2>
            </div>
            <br/>
            <br/>
            <br/>
            
            <br/>
        </div>
    </div>
  );
}