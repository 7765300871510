import RouterTree from './RoutesTree'
import './App.scss';
import './themes.scss';
import './button.scss';
import './common-main-page.scss';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import { BrowserRouter } from 'react-router-dom';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap/dist/js/bootstrap.bundle.min";

// font awesome icons
// not sure whether we need icons like below. Doesn't seem so
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
// import { faFacebookSquare, faGithub } from '@fortawesome/free-brands-svg-icons'
// library.add(fab, faGithub, faFacebookSquare);

function App() {
  const hasFull = true;//localStorage.getItem('tcareforyou-full-aaa') !== null;
  return (
    <div className="App">
      <BrowserRouter>
        <Header/>
          <main>
            <RouterTree/>
          </main>
        { hasFull &&
          <Footer/>
        }
      </BrowserRouter>
    </div>
  );
}

export default App;
