import './social-support.scss';
import imgTick from '../_assets/images/tick.png';
import titleImg from '../_assets/images/header-img-disabled-sm.jpg';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import NextStepPanel from '../NextStepPanel';

export default function DisabilitySupport() {
    // const [tipCounter, setCounter] = React.useState(0);
 
  return (
    <div className='social-support-container'>
        <div className='top-img-container'>
            <div className='plazhka'>Centring Care Around You: Where Your Needs Lead the Way</div>
            <img src={titleImg} alt="Disability support" />
        </div>
        <div className='wrapper text-centered-container divider-line-container my-4'>
            <div className='slogan-container'>
                <h1 className='text-smaller'>Are you interested in Disability Support?</h1>
            </div>
            <p className='heading'>At Tcare for You, we're here to make life more fulfilling for people with disabilities. Whether it's lending a hand around the house, providing companionship, helping you get to work or training, or coordinating enjoyable outings, our mission is to help you or your loved one lead a rich and satisfying life. </p>
            <br/>
            <p className='description'>We offer a flexible range of services, tailored to your preferences and needs, whether you need occasional assistance or more regular support. Just talk to us, and we'll create a plan that suits you. </p>
            <br/>
            <p className='description'>Our Community Support Professionals are experts in their field, and they're genuinely interested in getting to know you and what matters most. We serve individuals from diverse backgrounds and with various lifestyles and needs, ensuring that our support is as unique as you are.</p>
            <div className='my-4'>
                <Link to='tel:+61411776984' className='button ui primary m-3'>Call +61 411 776 984</Link>
                <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)} className='button ui primary m-3'>Request a call back</Link>
            </div>
        </div>
        <div className='wrapper support-options-container'>
            <div className='slogan-container'>
                <p className='text-smaller'>How to receive support?</p>
            </div>
            <div className='options-container'>
                <div className='left'>
                    <h3>National Disability Insurance Scheme (NDIS)</h3>
                    <p>The National Disability Insurance Scheme (NDIS) provides financial support for people living with disability. It's a lifelong approach customized to each individual's requirements and aspirations.</p>
                </div>
                <div className='vertical-divider-line'></div>
                <div className='right'>
                    <h3>Private care</h3>
                    <p>We provide a variety of disability support options, all centred around individual preferences and needs.</p>
                </div>
            </div>
            <br />
        </div>
        {/* <br /><br /> */}
        {/* <div className='wrapper ndis-tip-container my-5'>
            <div className='d-flex flex-row justify-content-center align-items-center my-4'>
                <FontAwesomeIcon icon={faArrowsRotate} height="40"  className='rotate-icon mx-4' onClick={() => setCounter(count => count + 1)}/>
                <h3 className='m-0'>Advice of the day</h3>
            </div>
            <p className={tipCounter % 5 == 0 ? 'description active' : 'description'}>Maximize the benefits of your National Disability Insurance Scheme (NDIS) plan by regularly reviewing your goals, seeking expert advice, and actively engaging with support services tailored to your needs.</p>
            <p className={tipCounter % 5 == 1 ? 'description active' : 'description'}>Enhance your NDIS experience by thoroughly understanding your eligibility, available resources, and effectively communicating your requirements to build a personalized support plan.</p>
            <p className={tipCounter % 5 == 2 ? 'description active' : 'description'}>To make the most of your NDIS funding, collaborate with experienced providers who specialize in your specific disability and can offer tailored assistance to improve your quality of life.</p>
            <p className={tipCounter % 5 == 3 ? 'description active' : 'description'}>Leverage the flexibility of your NDIS plan by exploring and utilizing a diverse range of services, supports, and technologies that align with your goals and foster greater independence in your daily life.</p>
            <p className={tipCounter % 5 == 4 ? 'description active' : 'description'}>Stay organized with your NDIS journey by keeping documentation up-to-date, tracking your budget, and attending meetings prepared with relevant questions and information about your needs.</p>
        </div> */}
        <div className='wrapper services-list-container my-5'>
            <div className='slogan-container'>
                <p className='text-smaller'>Our range of in-home support services</p>
            </div>
            <div className='services-container'>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Community Participation</h3>
                    </div>
                    <p>Assisting in actively participating and connecting with your local community</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <div className='services-item-title'><h3 className='m-2'>Post-Hospital Care Assistance</h3></div>
                    </div>
                    <p>Providing in-home support for people following illness or a hospital stay</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <div className='services-item-title'><h3 className='m-2'>Carer Relief Support</h3></div>
                    </div>
                    <p>Stepping in to assist when your primary carer is unavailable, ensuring continuous care and support</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Overnight Support</h3>
                    </div>
                    <p>Professional staff available for overnight stays or longer durations to ensure continuous assistance</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>In-Home Nursing Care</h3>
                    </div>
                    <p>Dedicated registered nurses providing health care directly within your home setting</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Mobility and Transport Support</h3>
                    </div>
                    <p>Helping you navigate and commute from one place to another with ease</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Domestic assistance</h3>
                    </div>
                    <p>Assisting you with a range of household duties and chores for a comfortable living environment</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Personal care</h3>
                    </div>
                    <p>Offering discreet and respectful care tailored to your personal needs, ensuring comfort and dignity</p>
                </div>
                    
            </div>
        </div>
        <NextStepPanel />
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className='wrapper slogan-container divider-line-container w-80'>
                <div className='divider-line'></div>
                <h2 className='text-smaller'>Tcare for You: </h2>
                <h2 className='text-smaller'>Support That You Can Trust</h2>
            </div>
            <br></br>
            <br></br>
        </div>
    </div>
  );
}