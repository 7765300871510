import './social-support.scss';
import imgTick from '../_assets/images/tick.png';
import titleImg from '../_assets/images/header-img-socialLifestyle-sm.jpg';
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import NextStepPanel from '../NextStepPanel';

export default function SocialSupport() {
    // const [tipCounter, setCounter] = React.useState(0);
 
  return (
    <div className='social-support-container'>
        <div className='top-img-container'>
            <div className='plazhka'>Life's Better Together: Enhancing Every Social Step</div>
            <img src={titleImg} alt="Social Support" />
        </div>
        <div className='wrapper text-centered-container divider-line-container my-4'>
            <div className='slogan-container'>
                <h1 className='text-smaller'>Are you in search of social and lifestyle support to enhance the quality of life for people with disabilities? Look no further. </h1>
            </div>
            <p className='heading'>Tcare for You offers a comprehensive suite of support services aimed at enriching overall well-being and improving the quality of life. </p>
            <br />
            <p className='description'>Our offerings encompass a diverse range of options, including companionship and transportation services, designed to facilitate participation in social gatherings, family events, volunteering, community engagements, and more. We tailor our support to your specific needs, whether it's for a few hours or on a regular basis. Your well-being is our priority.</p>
            <div className='my-4'>
                <Link to='tel:+61411776984' className='button ui primary m-3'>Call +61 411 776 984</Link>
                <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)} className='button ui primary m-3'>Request a call back</Link>
            </div>
        </div>
        <div className='wrapper support-options-container'>
            <div className='slogan-container'>
                <p className='text-smaller'>How to receive support?</p>
            </div>
            <div className='options-container'>
                <div className='left'>
                    <h3>National Disability Insurance Scheme (NDIS)</h3>
                    <p>The National Disability Insurance Scheme (NDIS) provides financial support for people living with disability. It's a lifelong approach customized to each individual's requirements and aspirations.</p>
                </div>
                <div className='vertical-divider-line'></div>
                <div className='right'>
                    <h3>Private care</h3>
                    <p>We provide a variety of disability support options, all centred around individual preferences and needs.</p>
                </div>
            </div>
            <br />
        </div>
        {/* <br /><br /> */}
        {/* <div className='wrapper ndis-tip-container my-5'>
            <div className='d-flex flex-row justify-content-center align-items-center my-4'>
                <FontAwesomeIcon icon={faArrowsRotate} height="40"  className='rotate-icon mx-4' onClick={() => setCounter(count => count + 1)}/>
                <h3 className='m-0'>Advice of the day</h3>
            </div>
            <p className={tipCounter % 5 == 0 ? 'description active' : 'description'}>Maximize the benefits of your National Disability Insurance Scheme (NDIS) plan by regularly reviewing your goals, seeking expert advice, and actively engaging with support services tailored to your needs.</p>
            <p className={tipCounter % 5 == 1 ? 'description active' : 'description'}>Enhance your NDIS experience by thoroughly understanding your eligibility, available resources, and effectively communicating your requirements to build a personalized support plan.</p>
            <p className={tipCounter % 5 == 2 ? 'description active' : 'description'}>To make the most of your NDIS funding, collaborate with experienced providers who specialize in your specific disability and can offer tailored assistance to improve your quality of life.</p>
            <p className={tipCounter % 5 == 3 ? 'description active' : 'description'}>Leverage the flexibility of your NDIS plan by exploring and utilizing a diverse range of services, supports, and technologies that align with your goals and foster greater independence in your daily life.</p>
            <p className={tipCounter % 5 == 4 ? 'description active' : 'description'}>Stay organized with your NDIS journey by keeping documentation up-to-date, tracking your budget, and attending meetings prepared with relevant questions and information about your needs.</p>
        </div> */}
        <div className='wrapper services-list-container my-5'>
            {/* <h1 className='m-4'>Our range of disability support services</h1> */}
            <div className='slogan-container'>
                <p className='text-smaller'>Our range of in-home support services</p>
            </div>
            <div className='services-container'>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Community Participation</h3>
                    </div>
                    <p>Engage locally and enhance social connections</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Skills Development</h3>
                    </div>
                    <p>Enhancing daily life skills for greater independence</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Recreation</h3>
                    </div>
                    <p>Explore tailored leisure activities and enrich lives</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Holiday and Travel</h3>
                    </div>
                    <p>Turning travel dreams into accessible realities for everyone</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Social Skills Training</h3>
                    </div>
                    <p>Enhancing social interactions and confidence</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Group Activities and Camps</h3>
                    </div>
                    <p>Inclusive group fun and skill-building camps</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Therapeutic Support</h3>
                    </div>
                    <p>Creative and compassionate therapies for enhanced well-being</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Mentorship and Peer Support</h3>
                    </div>
                    <p>Connect, understand and boost confidence with shared experiences</p>
                </div>
                <div className='services-item'>
                    <div className='heading my-2 d-flex flex-column justify-content-start align-items-center'>
                        <img src={imgTick} alt="" height="30" className='m-2' />
                        <h3 className='m-2'>Daily Activity Assistance</h3>
                    </div>
                    <p>Empowering daily routines and independence</p>
                </div>
                    
            </div>
        </div>
        <NextStepPanel />
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className='wrapper slogan-container divider-line-container w-80'>
                <div className='divider-line'></div>
                <h2 className='text-smaller'>Tcare for You: </h2>
                <h2 className='text-smaller'>Support That You Can Trust</h2>
            </div>
            <br></br>
            <br></br>
        </div>
    </div>
  );
}