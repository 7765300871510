import './footer.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import backgorundlines from '../_assets/images/Lines-PNG-Free-Download.png';

export default function Footer() {
  return (
    <footer>
        {/* <img src={backgorundlines} className='backgroundlines'></img> */}
            <div className="container w-75 py-5">
                <div className="grid-container d-flex flex-wrap justify-content-between py-3 mb-5">
                    <div className="left d-flex justify-content-center nav">
                        <div className="mx-4">
                            <h5 className='mb-3'>Tcare for you</h5>
                            <ul>
                                <li><a href="/about-us">About us</a></li>
                            </ul>
                        </div>
                        <div className="mx-4">
                            <h5 className='mb-3'>Our services</h5>
                            <ul>
                                <li><a href="/services/disability-support">Disability support</a></li>
                                <li><a href="/services/aged-care-support">Aged care</a></li>
                                <li><a href="/services/social-support">Social support</a></li>
                            </ul>
                        </div>
                        <div className="mx-4">
                            <h5 className='mb-3'>Contact us</h5>
                            <ul>
                                <li><a className='text-reset text-decoration-none' href="/contact-us">Contact us</a></li>
                                <li><a className='text-reset text-decoration-none' href="/career">Career</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* ComingSoon */}
                    {/* <div className="right mx-5">
                        <h5>Follow us on</h5>
                        <div className="social-links my-2">
                            <a className='text-reset text-decoration-none mx-2'
                                href="https://www.facebook.com/tcareforyou" target="_blank">
                                    <FontAwesomeIcon icon={["fab", "facebook-square"]} />
                            </a>
                            <a className='text-reset text-decoration-none mx-2'
                                href="https://au.linkedin.com/company/tcareforyou" target="_blank">
                                    <FontAwesomeIcon icon={["fab", "linkedin"]} />
                            </a>
                            <a className='text-reset text-decoration-none mx-2'
                                href="https://www.instagram.com" target="_blank">
                                    <FontAwesomeIcon icon={["fab", "instagram"]} />
                            </a>
                        </div>
                    </div> */}
                </div>
                <div className="disclaimer-links-container grid-container d-flex flex-wrap justify-content-center my-3" >
                    <span className='px-2'>© 2023 Tcare for you</span> 
                    <a className='px-2 text-reset text-decoration-none' href="/privacy-policy">Privacy policy</a>
                    <a className='px-2 text-reset text-decoration-none' href="/legal-disclaimer">Legal disclaimer</a>
                    {/* <a className='px-2 text-reset text-decoration-none' href="/googlesitemap">Site map</a>*/} {/* ComingSoon */}
                </div>
                <p className="text-center">
                Tcare for you upholds the principle of equal opportunity employment, ensuring that individuals are treated fairly and without bias, irrespective of race, hue, faith, gender, sexual orientation, gender identity, nationality, age, marital condition, genetic traits, disabilities, veteran status, or decisions related to advance directives. 
                Tcare for you pays respect to the indigenous custodians of this land, the Aboriginal and Torres Strait Islander peoples, and their continuing connection to land, sea, and community. We acknowledge and honor their rich cultures, histories, and traditions, and recognize their enduring contributions to this nation.
                </p>
            </div>
    </footer>
  );
}