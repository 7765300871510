// import imgCtapanelbg from './_assets/images/cta-panel-bg.png';
import { Link } from 'react-router-dom';

export default function NextStepPanel() {
    return (
        <div className='wrapper prompt-container'>
            {/* <img src={imgCtapanelbg} alt="" className='bg' /> */}
            <div className='container d-flex flex-row justify-content-center'>
                <div className='d-flex flex-column mx-5 my-3'>
                    
                    <h2 className='title mb-4'>Ready to move forward? Contact us now!</h2>
                    {/* <br/> */}
                    <div className='description mb-2'>Give us a call or request a call back at a time that suits you.</div>
                    <div className='description mb-2'>We're available from <strong>9:00am - 7:00pm, Monday to Saturday.</strong></div>
                </div>
                <div className='d-flex flex-column mx-5 my-3'>
                    <Link to="tel:+61411776984" className='button ui primary my-2 first'>Call +61 411 776 984</Link>
                    <Link to="/contact-us" onClick={() => setTimeout(() => window.scrollTo(0, 0), 0)} className='button ui primary my-2'>Request a call back</Link>
                </div>
            </div>
        </div>
    );
}