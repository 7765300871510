import './header.scss';
import logo from './company-logo-2.png';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export default function Header() {
  //assigning location variable
  const location = useLocation();
  const { pathname } = location;

  return (
    <header className="header shadow d-flex align-items-center">
     <div className="fulfill-parent blurred-background"></div>
     <div className="container d-flex flex-wrap justify-content-center align-items-center my-2">
         <div id="logo" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img
                src={logo}
                alt="TCare for you" /> 
            </Link>
         </div>
      
       <ul className="header-nav nav nav-pils d-flex align-items-center justify-content-center">
            <li className="header-nav-item nav-item">
                <div className={pathname === "/services/disability-support" ? "nav-link selected" : "nav-link"}>
                    <Link to="/services/disability-support" onClick={() => window.scrollTo(0, 0)}>Disability Support</Link>
                </div>
            </li>
            <li className="header-nav-item nav-item">
                <div className={pathname === "/services/aged-care-support" ? "nav-link selected" : "nav-link"}>
                    <Link to="/services/aged-care-support" onClick={() => window.scrollTo(0, 0)}>Aged Care</Link>
                </div>
            </li>
            <li className="header-nav-item nav-item">
                <div className={pathname === "/services/social-support" ? "nav-link selected" : "nav-link"}>
                    <Link to="/services/social-support" onClick={() => window.scrollTo(0, 0)}>Social &amp; Lifestyle Support</Link>
                </div>
            </li>
            <li className="header-nav-item nav-item">
                <div className={pathname === "/about-us" ? "nav-link selected" : "nav-link"}>
                    <Link to="/about-us" onClick={() => window.scrollTo(0, 0)}>About Us</Link>
                </div>
            </li>
            <li className="header-nav-item nav-item">
                <div className={pathname === "/career" ? "nav-link selected" : "nav-link"}>
                    <Link to="/career" onClick={() => window.scrollTo(0, 0)}>Career</Link>
                </div>
            </li>
            <li className="header-nav-item nav-item">
                <div className={pathname === "/contact-us" ? "nav-link selected" : "nav-link"}>
                    <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact us</Link>
                </div>
            </li>
       </ul>
     </div>
  </header>
  );
}