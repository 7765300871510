export default function Legal() {
  return (
    <div>
       <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <h1 className='text-small'>Legal disclaimer</h1>
          </div>
          <p className='heading'>Last update: 12/04/2023</p>
          <p className='heading'>All information provided on Tcare for you is for informational purposes only and does not constitute professional advice. While we strive to provide accurate and up-to-date information, we make no guarantees or warranties of any kind concerning the accuracy, reliability, or completeness of the content.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Commitment to Diversity, Inclusion, and Cultural Respect:</p>
          </div>
          <p className='heading'>Tcare for you upholds the principle of equal opportunity employment, ensuring that individuals are treated fairly and without bias, irrespective of race, hue, faith, gender, sexual orientation, gender identity, nationality, age, marital condition, genetic traits, disabilities, veteran status, or decisions related to advance directives. Tcare for you pays respect to the indigenous custodians of this land, the Aboriginal and Torres Strait Islander peoples, and their continuing connection to land, sea, and community. We acknowledge and honor their rich cultures, histories, and traditions, and recognize their enduring contributions to this nation.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Limitation of Liability:</p>
          </div>
          <p className='heading'>Tcare for you and its owners, operators, affiliates, and contributors shall not be held liable for any improper or incorrect use of the information described and/or contained on this website. We assume no responsibility for anyone's use of the information.

In no event shall Tcare for you be liable for any direct, indirect, incidental, special, exemplary, or consequential damages arising out of the use of the information provided. This includes, but is not limited to, loss of profits or data, even if we have been advised of the possibility of such damages.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>External Links:</p>
          </div>
          <p className='heading'>Some links on our website lead to external sites. Tcare for you does not endorse, guarantee, or take responsibility for the content or information found on external websites.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Copyright:</p>
          </div>
          <p className='heading'>Unless otherwise stated, all content on Tcare for you is the property of Tcare for you and may not be reproduced, distributed, or used in any manner without written permission from Tcare for you.</p>
          <div>Special attributions:</div>
          <ul className="w-50 text-centered-container m-auto">
              <li className="">Ui icons designed by Andrean Prabowo from Flaticon</li>
          </ul>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Changes to This Disclaimer:</p>
          </div>
          <p className='heading'>We may update this Legal Disclaimer from time to time. All changes will be reflected on this page, and we encourage users to frequently check this page for any updates.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Contact Us:</p>
          </div>
          <p className='heading'>If you have any questions regarding this Legal Disclaimer, please <a href='/contact-us'>contact us</a> using the contact details provided on our website.</p>
      </div>
      <br/><br/><br/><br/>
    </div>
  );
}