import React from 'react';
import './about.scss';

import { Link } from 'react-router-dom';
import aboutDirectorImg from '../_assets/images/aboutDirector-v2.jpg';
import imgValues from '../_assets/images/icon-values.png';
import imgMission from '../_assets/images/icon-mission.png';
import imgVision from '../_assets/images/icon-vision.png';
import titleImg from '../_assets/images/titleImg_about_us.png';
import imgCert1 from '../_assets/images/cert-logo-1.png';
import NextStepPanel from '../NextStepPanel';

export default function About() {
  return (
    <div className='about-container'>
        {/* <div className='top-img-container'>
            <img src={titleImg} alt="About us" />
        </div> */}
        <div className='wrapper text-centered-container divider-line-container my-4 title-container '>
            <div className='slogan-container mw-100'>
                <p className='text-smaller'>Tcare for You: Support That You Can Trust</p>
            </div>
            <div className='career-container'>
                <div className='left w-40'>
                    <p className='heading'>At Tcare for You, we are your dedicated partners in enhancing lives and fostering inclusivity</p>
                </div>
                <div className='right w-60'>
                    <p>At Tcare for You, we are more than just a care provider; we are your trusted partners in your journey towards a better quality of life. Established in 2021, our agency is led by a team of compassionate women who share a common commitment to making a positive impact on the lives of individuals living with disabilities. We take pride in being a registered NDIS provider, ensuring that our services adhere to the highest standards and meet the rigorous requirements of the National Disability Insurance Scheme.</p>
                </div>
            </div>
        </div>
        {/* <div className='wrapper slogan-container divider-line-container w-75'>
            <div className='divider-line'></div>
            <h3>NDIS registered service provider. Tcare for you</h3>
        </div> */}
        
        <div className='divider-line-container'>
            <div className='divider-line'></div>
        </div>
        <div className='wrapper mission-container my-5'>
            <br/>
            <br/>
            {/* <h2 className='title'>Our Story</h2> */}
            <div className='vision-container my-4 d-flex flex-row justify-content-center'>
                <div className='vision mx-3'>
                    <div className='heading my-3 d-flex flex-row justify-content-start align-items-center'>
                        <img src={imgVision} alt="" height="35" className='m-2' />
                        <h3 className='m-2'>Our Vision</h3>
                    </div>
                    <p>
                    Our vision is to create a world where individuals with disabilities have equal opportunities, access, and support to live life to the fullest. We aim to be leaders in the field of disability support, setting new standards for care, accessibility, and inclusivity.
                    </p>
                </div>
                <div className='mission mx-3'>
                    <div className='heading my-3 d-flex flex-row justify-content-start align-items-center'>
                        <img src={imgMission} alt="" height="35" className='m-2' />
                        <h3 className='m-2'>Our Mission</h3>
                    </div>
                    <p>
                    At Tcare for You, our mission is clear: we exist to empower individuals living with disabilities by providing affordable and accessible services that cater to their unique and evolving requirements. We understand that every person is unique, and their journey towards independence and well-being is personal. That's why we are committed to tailoring our services to meet the individual goals, preferences, and aspirations of each client.
                    </p>
                </div>
                <div className='values mx-3'>
                    <div className='heading my-3 d-flex flex-row justify-content-start align-items-center'>
                        <img src={imgValues} alt="" height="35" className='m-2' />
                        <h3 className='m-2'>Our Values</h3>
                    </div>
                    <p>Our values are the guiding principles that shape everything we do. We believe in:</p>
                    <ul className="list">
                        {/* <li className='mb-2'>Compassion: Our team is driven by a deep sense of compassion. We approach every client with empathy and respect. </li> */}
                        <li className='mb-2'>Compassion: Approaching every client with empathy and respect.</li>
                        <li className='mb-2'>Inclusivity: Ensuring accessibility and support for all.</li>
                        <li className='mb-2'>Quality: Delivering high-quality, personalized care.</li>
                        <li className='mb-2'>Empowerment: Enabling individuals to lead fulfilling and independent lives.</li>
                    </ul>
                </div>
                
            </div>
        </div>
        <NextStepPanel />
        {/* <div className='wrapper intro-container'>
            <div className='left w-40'>
            <p>In 2013, <i>Caring Brands International </i>was developed and launched as a future-facing healthcare enterprise to address the global shift that has begun. The first acquisition in September 2013 under the enterprise umbrella was Bluebird Care in the UK and the Republic of Ireland. In September 2014, Just Better Care, operating in Australia, joined the family of care franchise brands under Caring Brands International.</p>
            </div>
            <div className='right w-60'>
            <p>Today, there are three market-leading franchise care brands with more than 300 franchise owners operating in 530 locations in seven countries. Together, they provide services to over 200,000 people annually and generate nearly one billion dollars US in combined system-wide sales.</p>
            <p>Based in the continental United States, Interim HealthCare Inc., founded in 1966, is a leading national franchisor of home care, hospice and healthcare staffing. Franchisees employ nurses, therapists, aides, companions and other healthcare professionals, who provide 25 million hours of home care service to 190,000 people each year, meeting a variety of home health, senior care, hospice, palliative care, paediatric care and healthcare staffing needs.</p>
            <p>Founded in 2004, Bluebird Care has more than 180 franchise locations operated by over 150 franchisees and provides non-medical care services in England, Wales, Scotland, Northern Ireland and the Republic of Ireland. Bluebird Care is headquartered in Petersfield, Hampshire, England. Bluebird Care provides over 4.5 million care visits at home every year, which translates to 12,500 care visits to over 6,300 customers each day.</p>
            <p><strong>Just Better Care</strong>, founded in 2005, is based in Manly, New South Wales, Australia, and is a well-known franchisor of in-home non-medical and medical care services throughout Australia. The company has one corporate operation and over 30 independently owned and franchised operations across Australia, including in New South Wales, the ACT, Victoria, Western Australia and Queensland.</p>
            </div>
        </div> */}
        <div className='wrapper services-overview-container'>
            <div className='services-overview-item-wrapper'>
                
                <div className='services-overview-text-container'>
                    <h3>Our Director: Tatiana Lelekova</h3>
                    <p>Our Director, Tatiana Lelekova, brings over two decades of invaluable experience as a dedicated nurse. Her unwavering passion for improving the lives of those in need has been the cornerstone of our agency. </p>
                    <p>Tatiana's commitment to excellence, coupled with her empathetic approach, ensures that Tcare for You is founded on principles of genuine care, professionalism, and a steadfast dedication to making a positive impact on the lives of our clients.</p>
                </div>
                <div className='services-overview-img-container img-about-us'>
                    <img src={aboutDirectorImg} alt="" />
                </div>
            </div>
        </div>
        {/* <div className='divider-line-container'>
            <div className='divider-line'></div>
        </div> */}
        <div className='wrapper certification-container '>
            <div className='container p-5 d-flex flex-row justify-content-start align-items-center'>
                <div className='logo m-4'>
                    <img src={imgCert1} alt="" height="150" />
                </div>
                <div className='m-4'>
                    <h2 className='mb-4'>Proud to Be NDIS Registered</h2>
                    <p className='mb-2'>
                    We take immense pride in being a registered NDIS provider. This registration underscores our commitment to adhering to the highest standards and meeting the rigorous requirements of the National Disability Insurance Scheme. It reflects our dedication to providing you with the best possible care and support, and we are honoured to be your trusted choice in this journey towards a brighter future.
                    </p>
                </div>
            </div>
        </div>
    </div>
  );
}