import React from 'react';

export default function ReachOutForm(link: any) {

  const handleSubmit = function(e: any) {
    e.preventDefault();
    console.log(link.link);
    const formEl: HTMLFormElement | undefined = e.target as HTMLFormElement | undefined;
    const formData = new FormData(formEl);
    fetch(link.link, {
            method: "POST",
            body: formData,
            headers: {
                "Accept": "application/json",
            },
        })
        .then(response => {
            e.nativeEvent.submitter.innerHTML = "<a>Done</a>";
            console.log("Submitted");
        })
        .catch(error => {
            console.log(error);
            e.nativeEvent.submitter.innerHTML = "<a>Done</a>"
        })
        .finally(() => {
            setTimeout(() =>{
                e.nativeEvent.submitter.innerHTML = "<a>Submit</a>";
            }, 3000);
        });
  }
  return (
    <form className='w-100 d-flex flex-wrap pt-3'
        onSubmit={handleSubmit}>
        <div className='form-item form-item-top'>
            <label className='m-0 pb-2'>First name</label>
            <input type="text" className='w-100' name='firstName' required />
        </div>
        <div className='form-item form-item-top'>
            <label className='m-0 pb-2'>Last name</label>
            <input type="text" className='w-100' name='lastName' required />
        </div>
        <input type="hidden" name="_gotcha" className='d-none'></input>
        <div className='form-item form-item-top'>
            <label className='m-0 pb-2'>Email</label>
            <input type="email" className='w-100' name='email' required />
        </div>
        <div className='form-item form-item-top'>
            <label className='m-0 pb-2'>Phone (optional)</label>
            <input type="text" className='w-100' name='phone'  />
        </div>
        <div className='form-item form-item-message'>
            <label className='m-0 pb-2'>Message</label>
            <textarea cols={40} rows={6} name='message' required />
        </div>
        <button type='submit' className='button ui primary submit-button' value={'Submit'}><a>Submit</a></button>
    </form>
  );
}