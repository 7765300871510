export default function PrivacyPolicy() {
  return (
    <div>
       <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <h1 className='text-small'>Privacy Policy</h1>
          </div>
          <p className='heading'>Last update: 12/04/2023</p>
          <p className='heading'>At Tcare for you, we are committed to maintaining the trust and confidence of visitors to our website. In this Privacy Policy, we provide detailed information on when and why we collect your personal information, how we use it, and how we keep it secure.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Information We Collect:</p>
          </div>
          <p className='heading'>While using our website, the only personal data we collect is through our "Contact Us" form. This data may include: Name, Email address, Phone number (optional), any other information you might provide in the message field.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Why We Collect Your Data:</p>
          </div>
          <p className='heading'>We use the information provided in our "Contact Us" form only for the purpose of responding to your inquiries or providing the services you've requested. The data you provide shall not be used for other purposes.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>How We Store Your Data:</p>
          </div>
          <p className='heading'>Your data is stored securely and is only accessible by authorized personnel. It is never sold, rented, or shared with third parties, unless required by law.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Your Rights:</p>
          </div>
          <p className='heading'>You have the right to: Ask for a copy of the personal data we hold about you. Request corrections or deletions of your personal data (unless we have a legitimate reason for retaining it). Opt-out of any marketing communications by clicking "unsubscribe" in the email or by emailing us at <a href='info@tcareforyou.com.au'>info@tcareforyou.com.au</a></p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Updates to this Privacy Policy:</p>
          </div>
          <p className='heading'>We may update this Privacy Policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. Please revisit this page periodically to stay aware of any changes.</p>
      </div>
      <div className='wrapper text-centered-container divider-line-container my-4 title-container'>
          <div className='slogan-container mw-100'>
              <p className='text-smaller'>Contact Us:</p>
          </div>
          <p className='heading'>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please <a href='/contact-us'>contact us</a> using the contact details provided on our website.</p>
      </div>
      <br/><br/><br/><br/>
    </div>
  );
}