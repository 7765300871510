import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import Home from './Home/Home';
import About from './About/About';
import SocialSupport from './Support/SocialSupport';
import ContactUs from './ContactUs/ContactUs';
import Career from './Career/Career';
import DisabilitySupport from './Support/DisabilitySupport';
import AgedCareSupport from './Support/AgedCareSupport';
import ComingSoon from './ComingSoon';
import PrivacyPolicy from './PrivacyLegal/PrivacyPolicy';
import Legal from './PrivacyLegal/Legal';

export default function RoutesTree() {
  const hasFull = true;//localStorage.getItem('tcareforyou-full-aaa') !== null;
  return (
    <div>
      {
        !hasFull
          ?
          <Routes>
            <Route path="/*" element={<ComingSoon/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
          </Routes>
          :
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/services/disability-support" element={<DisabilitySupport/>} />
              <Route path="/services/aged-care-support" element={<AgedCareSupport/>} />
              <Route path="/services/social-support" element={<SocialSupport/>} />
              <Route path="/about-us" element={<About/>} />
              <Route path="/career" element={<Career/>} />
              <Route path="/contact-us" element={<ContactUs/>} />

              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
              <Route path="/legal-disclaimer" element={<Legal/>} />
              
        </Routes>
      }
    </div>
  );
}