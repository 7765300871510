import './contact-us.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import ReachOutForm from './ReachOutForm';

export default function ContactUs() {
  return (
    <div>
        <div className='wrapper mb-0'>
            <div className='slogan-container'>
                <h2>Contact us</h2>  
            </div>
        </div>
        <div className='wrapper contact-us-container mt-0'>
            <div className='reach-out-container m-5 p-4'>
                <h2>Reach out to us</h2>
                <div className='my-4 mx-3'>
                <p >Please contact us and our team members will be in touch with you shortly</p>
                <ReachOutForm link="https://getform.io/f/dd790b81-fa90-4ae8-b551-eacf82d1f9a0"/>
                </div>
            </div>
            <div className='contacts-container m-5'>
                <h2>Contact our team</h2>
                <div>
                    <div className='d-flex align-items-center my-2'>
                        <FontAwesomeIcon icon={faPhone} height="40"  className='rotate-icon mr-3'/>
                        <span>+61 411 776 984</span> 
                    </div>
                    <div className='d-flex align-items-center my-2'>
                        <FontAwesomeIcon icon={faEnvelope} height="40"  className='rotate-icon mr-3'/>
                        <span>info@tcareforyou.com.au</span>
                    </div>
                </div>
                <br/>
                <br/>
                <h2>Work with us?</h2>
                <p>Our dedicated team members are always happy to discuss possible work opportunities with you</p>
                <div>
                    <div className='d-flex align-items-center my-2'>
                        <FontAwesomeIcon icon={faPhone} height="40"  className='rotate-icon mr-3'/>
                        <span>+61 411 776 984</span> 
                    </div>
                    <div className='d-flex align-items-center my-2'>
                        <FontAwesomeIcon icon={faEnvelope} height="40"  className='rotate-icon mr-3'/>
                        <span>careers@tcareforyou.com.au</span>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  );
}